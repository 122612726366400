<template>
  <div class="hello">
      <div class="headerSpan"  v-if="!testUserRights">Narcotics Admin<span class="floatRight"><b-btn v-if="false" size="sm" @click="showAdmin = !showAdmin" :variant="checkState">Admin</b-btn></span></div>
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>
      <div  v-if="userData.role == 'adminchief' || userData.role == 'admin' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Narcotic Reporting">
              <narcoticReportingC2G :loading="loadingMedicationCard" :medsNarctrack="medsNarctrack" v-if="medsNarctrack" />
              </b-tab>
              <b-tab title="Medication Admin">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="All Medications">
                <narcoticMedication  :medsNarctrack="medsNarctrack" :medicationCardsInitial="medicationCardsInitial" @updateMedications="getMedications" />
                    </b-tab>
                    <b-tab title="Quarantine" @click="getQuarMedications">
                  <narcoticMedicationQuar v-if="quarMedicationsData" :quarMedicationsData="quarMedicationsData" :destroyMedicationsData="destroyMedicationsData" :medsNarctrack="medsNarctrack" :medicationCardsInitial="medicationCardsInitial" @updateMedications="getMedications" />
                     <h5 v-else>Loading Data...</h5>
                      </b-tab>
                      </b-tabs>
                      </b-card>
              </b-tab>
              <b-tab title="Blood Admin" v-if="siteconfig.bloodBankForm">
                <narcoticBlood  :medsNarctrack="medsNarctrack" :medicationCardsInitial="medicationCardsInitial" @updateMedications="getMedications" />
              </b-tab>
              </b-tabs>
              </b-card>
     
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
import narcoticReporting from './narcotics/narcoticReporting.vue'
import narcoticReportingC2G from './narcotics/narcoticReportingC2G.vue'
import narcoticMedication from './narcotics/narcoticMedication.vue'
import narcoticBlood from './narcotics/narcoticBlood.vue'
import narcoticMedicationQuar from './narcotics/narcoticMedicationQuar.vue'
export default {
  name: 'hello',
  components: {
    narcoticReporting,
    narcoticMedication,
    narcoticMedicationQuar,
    narcoticReportingC2G,
    narcoticBlood
  },
  data () {
    return {
      medicationCardsInitial: [],
      quarMedicationsData: null,
      destroyMedicationsData: null,
      loadingMedicationCard: false
    }
  },
  created(){
      this.getMedicationsCards(this.medsNarctrack)
      bus.$on('updateMedicationsTracking', this.getMedications)
      bus.$on('updateMedicationsTracking', this.getQuarMedications)

  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi',
        'medsNarctrack',
        'siteconfig'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)){
            return false
          } else {
            return true
          }
      },
    

  },
  watch: {
    medsNarctrack(newData){
      this.getMedicationsCards(newData)
    }
 
},
  methods: {
    
  postImage(e, id){
      this.fileDocumentId = id
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUpload = response.data

        });

  
     },
     getMedications(){
      this.loadingMedicationCard = false
        var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
         var payload = { id: this.userData.id, headers: headers }
        this.$store.dispatch('LOAD_MEDICATION_DATA', payload).then(
                (response) => {
               this.loadingMedicationCard = true
                    this.getMedicationsCards(response)
                }
         )
     },
     getQuarMedications(){
        axios({
         method: 'get',
         url: '/api/v1/content/dailynarctrack/cardtype/quarantine',
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then(response => {
        this.quarMedicationsData = response.data
       })
       this.getDestroyed()
     },
    getDestroyed(){
        axios({
         method: 'get',
         url: '/api/v1/content/dailynarctrack/cardtype/destroy',
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then(response => {
        this.destroyMedicationsData = response.data
       })
    },
    getMedicationsCards(){
      axios({
         method: 'get',
         url: '/api/v1/content/dailynarctrack/cardtype/initial',
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then(response => {
        this.medicationCardsInitial = response.data

       })
     },
     getMedicationsCardsBacK(data){
       var array = data.map(p => p.id)
       axios({
         method: 'post',
         url: '/api/v1/content/dailynarctrack/medcards',
         data: {med_ids: array},
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then(response => {
        this.medicationCardsInitial = response.data

       })
     },
    updateDocument(id){
    axios({
     method: 'patch',
     url: '/api/v1/content/document/' + id,
     data: {
     'documentName': this.detailedDescription,
     'documentTag': this.documentTag,
      'documentTagParent': this.docPar,
       'documentId': this.itemShort,
      'documentFile': this.fileUpload

           },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
    // console.log(response.data)
     this.resetComponent()
   })

 }
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
