<template>
  <div :class="{panelCheck: show}" v-if="apparatusData.length > 0">

      <h5 @click="toggleShow(dailyvehicle)" id="dailyvehicleTitle" :class="getShiftColor(dailyvehicle.dailyvehicleShift)">
        {{ dailyvehicle.title }} 
        <span v-if="flagStatus" class="flagWarning">&#9873; </span>
        <span v-if="bottleAdditions && siteconfig.deptAbbr == 'PGFD'" :class="bottleAdditions ? 'flagGood' : 'flagWarning'">&#127312; </span> </h5>

        <span v-if="!show"><h6>Current:{{ dailyvehicle.dailyvehicleHoursMiles }} {{checkVehicleType(dailyvehicle)}} | Till Pm:{{ getTillPm(dailyvehicle)}} 
          </h6></span>


      <div v-if="show" class="show-background">
        <b-container class="headerContainer" :class='getShiftColor(dailyvehicle.dailyvehicleShift) + "-header"'>
        <b-row>
          <b-col sm="4" align="left">
            <h3 v-if="!editPost">{{ dailyvehicle.dailyvehicleApparatusId }}</h3>
        <multiselect v-if="editPost" v-model="apparatusSelectValue" :options="filteredVehicleData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Select Vehicle ID" label="apparatusName" track-by="apparatusName"></multiselect>
          </b-col>
          <b-col align="right">
            <h5 v-if="!editPost">Author: {{ dailyvehicle.dailyvehicleAuthor }}</h5>
          <multiselect  class="mb-2" v-if="editPost" v-model="stationSelectValue" :options="filteredStationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Select Current Station" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
                </multiselect>
                
          </b-col>
          <b-col v-if="false" cols="auto" align="right">
            <b-btn v-if="!editPost" variant="secondary" size="sm" @click="setEditData(dailyvehicle)">Edit</b-btn>
            <b-btn v-if="editPost" variant="danger" size="sm" @click="cancelEditData">Cancel</b-btn>
          </b-col>
        </b-row>
        </b-container>
        <b-row class="px-3">
                <b-col v-if="editPermissionCheck(dailyvehicle) && editPost" align="left">
            
              <b-btn v-if="editPost"  size="sm" @click="deleteVehicleCheck" variant="outline-danger">Delete</b-btn>
              </b-col>
        <b-col v-if="editPermissionCheck(dailyvehicle)" align="right">
                    <b-btn v-if="!editPost" size="sm" @click="setEditData(dailyvehicle)" variant="outline-primary" title="Edit Form">	&#x270e;</b-btn>
              <b-btn v-if="editPost"  size="sm" @click="cancelEditData" variant="outline-danger">Cancel</b-btn>
              </b-col></b-row>
      <div v-if="!editPost">

        <table id="confirmationTable">
          <tr class="headerRows"><td><h6>Date</h6></td><td><h6>Shift</h6></td></tr>
          <tr class="subHeaderRows"><td><h6>{{ getDate(dailyvehicle.dailyvehicleDate) }}</h6></td><td><h6>{{ dailyvehicle.dailyvehicleShift }}</h6></td></tr>
          <tr class="headerRows"><td><h6>Current {{ hoursOrMiles }}</h6></td><td><h6>{{ hoursOrMiles }} Till Pm</h6></td></tr>
          <tr class="subHeaderRows"><td><h6>{{ dailyvehicle.dailyvehicleHoursMiles }}</h6></td><td><h6 v-if="nextPM == ''">Missing</h6><h6 v-if="nextPM != ''">{{ nextPM - dailyvehicle.dailyvehicleHoursMiles }}</h6></td></tr>
        </table>
        <b-container class="checkDetails" v-if="checkDetailsTab">
                <div class="checkDetailsWrap">
                <h4>Detailed Check Data</h4>
                <b-card no-body>
                  <b-tabs ref="tabs" card>
                   <b-tab title="Items" active>
                     <div class="checkDetailsDiv" v-for="(detail, index) in dailyvehicle.dailyvehicleCheckItems" :key="index">
                       <h5 id="details">
                        <span v-if="detail.value == 'Yes'" :class="detail.value"> &#10004; </span>
                        <span v-if="detail.value == 'No'" :class="detail.value"> &#10008; </span>
                        <span v-if="detail.type == 'Number'" class="numberDetail">x{{ detail.value }}</span> 
                   <span v-if="detail.type != 'Signature'">{{ detail.item }}</span>
                   <span v-if="detail.type == 'Signature' && detail.user && detail.user.lastName && detail.show != undefined"><b-btn @click="detail.show = !detail.show" variant="success" size="sm">Show {{ detail.user.lastName }}'s {{ detail.item }}</b-btn></span>
                       </h4>
                      <p class="defDescrip" v-if="detail.description">{{ detail.description }}</p>
                  <b-row v-if="detail.type == 'Signature' && detail.value && !detail.show"><b-col cols="8"><b-img :src="detail.value" /></b-col></b-row>
                       </h5>
                      <p class="defDescrip" v-if="detail.description">{{ detail.description }}</p>
                </div>
              </b-tab>
               <b-tab title="Details" active>
                     <div class="checkDetailsDiv" v-for="(detail, index) in dailyvehicle.dailyvehicleCheckDetails" :key="index">
                       <h5 id="details">
                        <span v-if="detail.pass == 'Yes'" :class="detail.pass"> &#10004; </span>
                        <span v-if="detail.pass == 'No'" :class="detail.pass"> &#10008; </span>
                      {{ detail.area }}
                       </h5>
                      <p class="defDescrip" v-if="detail.description">{{ detail.description }}</p>
                </div>
              </b-tab>
              <b-tab v-if="!hideBottle" id="bottle" title="Bottles" @click="bottleCheck(dailyvehicle)">
                <div v-if="bottleCheckData.length > 0" v-for="(bottle, index) in bottleCheckData" :key="index"><h5 v-if="!showModal">
                  <b-btn v-if="checkBottleEditPermission(bottle, dailyvehicle)" v-show="bottleCheckEdit" @click="removeBottleData(index)" size='sm' variant="danger">delete</b-btn>
                  Checked by: {{ bottle.bottleAuthor }}
                   <b-btn v-if="checkBottleEditPermission(bottle, dailyvehicle)" v-show="!bottleCheckEdit" @click="bottleCheckEditData" size='sm' variant="primary">edit</b-btn></h5>
                  <b-table v-if="!showModal" v-show="!bottleCheckEdit" hover striped :items="bottle.bottleDataArray" />
                  <bottlecheck v-show="checkBottleEditPermission(bottle, dailyvehicle)" v-if="bottleCheckEdit" :dailyvehicle="dailyvehicle" :bottleIndex="index" :bottleArrayPreviousData="bottle.bottleDataArray" />
                </div>
                  <b-btn v-if="!showModal" v-show="!bottleCheckEdit" @click.stop="showModal = !showModal ">Add New Bottle Data</b-btn>

                  <bottlecheck v-if="showModal" :dailyvehicle="dailyvehicle" />
              </b-tab>
            </b-tabs>
          </b-card>
              </div>
              </b-container>

      </div>
    <div v-if="editPost">
      <table id="confirmationTable">
        <tr class="headerRows"><td><h6>Date</h6></td><td><h6>Shift</h6></td></tr>
        <tr class="subHeaderRows"><td><h6>{{ getDate(dailyvehicle.field_date_of_check) }}</h6></td><td><h6>{{ dailyvehicle.dailyvehicleShift }}</h6></td></tr>
        <tr class="headerRows"><td><h6>Current {{ hoursOrMiles }}</h6></td><td><h6>{{ hoursOrMiles }} Till Pm</h6></td></tr>
        <tr class="subHeaderRows"><td><h6><input class="big" type="number" inputmode="numeric" pattern="[0-9]*" v-model="checkHours" /></h6></td><td><h6 v-if="nextPM == ''">Missing</h6><h6 v-if="nextPM != ''">{{ nextPM - checkHours }}</h6></td></tr>
      </table>
      <b-container class="checkDetails">
        <b-card no-body>
                  <b-tabs ref="tabs" card>
                    <b-tab title="Items">
              <div class="checkDetailsWrap">
              <h4>Detailed Check Data </h4>
              <div class="checkDetailsDiv" v-for="(detail, index) in checkItems" :key="index">
                <h5 id="details">
                  <input v-if="detail.type == 'Number'" class="big" type="number" inputmode="numeric" pattern="[0-9]*" v-model="detail.value" />
                  <select v-if="detail.type == 'Boolean'" v-model="detail.value"><option value="Yes">Yes</option><option value="No">No</option></select>
                  {{ detail.item }}
                </h5>
                
                <b-row v-if="detail.type == 'Signature' && !detail.value">
                      <b-col><multiselect v-model="signatureWitness[detail.item]" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer (Officer)">
                 <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName.charAt(0) }}. {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName.charAt(0) }}. {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </multiselect></b-col></b-row>
                    <b-row v-if="detail.type == 'Signature' && signatureWitness[detail.item] && !detail.value"><b-col><b-btn variant="primary" size="sm" @click="getUserSignature(signatureWitness[detail.item], index)">Add {{ signatureWitness[detail.item].lastName }}'s Signature</b-btn></b-col></b-row>
                    <b-row v-if="detail.type == 'Signature' && detail.value && detail.show" class="signatureRow"><b-col cols="8"><b-img :src="detail.value" /></b-col><b-col cols="*"><b-btn variant="danger" size="sm" @click="detail.value = ''">X</b-btn></b-col></b-row>
                  <textarea  class="defDescrip" v-model="detail.description" v-if="detail.pass == 'No'" placeholder="Please Provide a Detailed Description of the Issue"></textarea>
                  </div>
                  </div>
                    </b-tab>
                   <b-tab title="Details" active>
              <div class="checkDetailsWrap">
              <h4>Detailed Check Data </h4>
              <div class="checkDetailsDiv" v-for="(detail, index) in checkDetails" :key="index">
                <h5 id="details">
                  <select v-model="detail.pass"><option value="Yes">Yes</option><option value="No">No</option></select>
                  {{ detail.area }}
                </h5>
                  <textarea  class="defDescrip" v-model="detail.description" v-if="detail.pass == 'No'" placeholder="Please Provide a Detailed Description of the Issue"></textarea>
                  </div>
                  </div>
                   </b-tab>
                  </b-tabs>
        </b-card>
            <b-btn @click="updateLogbookPost(dailyvehicle)" variant="primary">Submit Changes</b-btn>
            </b-container>

    </div>
  </div>
    <signature v-if="userSign" :showModalSignature="userSign" :name="signatureObj.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="signatureClose"></signature>
    </div>
  
</template>


<script>
/*

*/
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import BottleCheck from '../../inputfields/bottlecheckoff.vue'
import VueTimePicker from '../../inputfields/timepicker.vue'
import { bus } from '../../../main.js'
import Multiselect from 'vue-multiselect'
import signature from "../../inputfields/signaturepad.vue";
export default {
  props:{
      dailyvehicle: {
          type: Object
      },
      showPost: {
          type: Boolean,
          default: false,
      },
      hideBottle: {
          type: Boolean,
          default: false
      }
  },
components:{
    'bottlecheck' : BottleCheck,
    'timepicker' : VueTimePicker,
    'multiselect': Multiselect,
    signature
},
  data () {
    return {
      trueBoo: false,
      editPost: false,
      show : false,
      showUrl : false,
      editButton: 'edit',
      signatureWitness: {},
      signatureObj: {},
      userSign: false,
      showEditView: false,
      showModal: false,
      bottleCheckEdit: false,
      apparatusSelectValue: '',
      stationSelectValue: '',
      checkDetailsTab: true,
      checkDate: '',
      checkHours: '',
      checkItems: [],
      checkDetails: [],
      checkAmps: '',
      bottleCheckData: [],
      bottleCheckArray: [],
      nextPM: '',
    }
  },
  created(){
    this.show = this.showPost
    bus.$on('hideBottle', this.showModalToggle)
    bus.$on('pullBottleCheckData', this.clearBottleSetup)
  },
  watcher: {

  },
  computed: {
    ...mapState([
          'getSessionData',
          'userData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'siteconfig'
      ]),
      flagStatus(){
        var check = this.dailyvehicle.dailyvehicleCheckDetails
        var pass = false
        for(var i in check){
          if(check[i].pass == 'No'){
            var pass = true
          }
        }
        return pass
      },
      bottleAdditions(){
        return this.dailyvehicle && this.dailyvehicle.dailyvehicleBottleData ? true : false
      },
      filteredStationsData(){
        return this.stationsData /*.filter( (p) => {
                return p.stationName.match('Station')
                      || p.stationName.match('Administration')
              }) */
      },
      filteredVehicleData(){
          var filterUser = this.apparatusData.filter( p => {
      var index = p.apparatusMenuVisibility.indexOf(this.userData.role)
      if(index >= 0){
        return p
          }
        }) 
        return filterUser
        },
      hoursOrMiles(){
        var apparatus = this.apparatusData.filter(p => {
          return p.apparatusName.match(this.dailyvehicle.dailyvehicleApparatusId)
        })
        if(apparatus.length > 0 && apparatus[0].apparatusNextPm != null){
          this.nextPM = apparatus[0].apparatusNextPm
        }
        if(apparatus.length > 0 && apparatus[0].apparatusPMCheckType != null){
        return apparatus[0].apparatusPMCheckType
      } }
  },
  methods: {    
    getShiftColor(shiftdata){
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shiftdata)
      if(obj){
        return obj.color
      } return shift
    },
    getTillPm(data){
       var veh = this.apparatusData.find(p => p.id == data.dailyvehicleApparatusDbid)
       if(veh){
          var vehHours = veh.apparatusNextPm - data.dailyvehicleHoursMiles
          var holdType = veh.apparatusPMCheckType
          return vehHours + ' ' + holdType
       } else {
         return 'Missing Data'
       }
    },
        getUserSignature(user, index){
      this.signatureObj = user
      this.holdItem = index
      this.userSign = true
    },
        signatureClose() {
      this.userSign = false;
      this.witnessSign = false;
      this.showModalNarc = true;
    },
        signatureMethodUser(data) {
      this.userSign = false;
      this.showModalNarc = true;
      this.checkItems[this.holdItem].value = data
      this.checkItems[this.holdItem].user = this.signatureObj
      this.checkItems[this.holdItem].show = true
     // this.processFileUriForUpload(data);
    },
    checkVehicleType(data){
      var veh = this.apparatusData.find(p => p.id == data.dailyvehicleApparatusDbid)
      if(veh){
          var holdType = veh.apparatusPMCheckType
          return holdType
       } else {
         return ''
       }
    },
    bottleCheckEditData(event){
      this.bottleCheckEdit = true
      this.showModal = false
    },
    showModalToggle(event){
      if(this.bottleCheckEdit == true){
        this.bottleCheckEdit = false
        this.showModal = false
      } else {
      this.showModal = !this.showModal
      this.bottleCheckEdit = false }
    },
    bottleSeperator(data){
      if(data != undefined){
      var bottleArray = data.split(',')
      var bottleObj = []
      for(var i = 0; i < bottleArray.length; i++){
        var initial = bottleArray[i].split(' | ')
        var bid = initial[0].replace('id:', '')
        var btype = initial[1].replace('type:', '')
        var bpressure = initial[2].replace('pressure:', '')
        var object = { Id: bid, Type: btype, Pressure: bpressure}
        bottleObj.push(object)
      }
      return bottleObj
    }
    else {
      return
    }
    },
    setEditData(data){
      this.checkDate = moment(data.field_date_of_check).format('YYYY-MM-DD')
      this.checkHours = data.dailyvehicleHoursMiles
      this.checkItems = []
      var check = data.dailyvehicleCheckItems
      for(var i in check){
        if(check[i].type != 'Signature'){
          this.checkItems.push(check[i])
        } else {
          this.signatureWitness[check[i].item] = check[i].user
          var obj = check[i]
          obj.show = true
          this.checkItems.push(obj)
        }
      }
      this.checkDetails = data.dailyvehicleCheckDetails
      if(data.dailyvehicleStation){
        var stationObj = this.stationsData.find(p => p.stationName == data.dailyvehicleStation)
        if(stationObj){
          this.stationSelectValue = stationObj
        }
      }
      if(data.dailyvehicleApparatusDbid){
        var stationObj = this.apparatusData.find(p => p.id == data.dailyvehicleApparatusDbid)
        if(stationObj){
          this.apparatusSelectValue = stationObj
        }
      }
      this.editPost = true
    },
    cancelEditData(){
      this.checkDate = ''
      this.checkHours = ''
      this.checkHoursTill = ''
      this.checkPortable = ''
      this.checkKnox = ''
      this.checkDetails = ''
      this.editPost = false
    },
    bottleCheck(data){
        this.bottleCheckData = data.dailyvehicleBottleData
    },
    clearBottleSetup(){
      this.bottleCheck(this.dailyvehicle)
      this.checkDetailsTab = false
      this.showModal = false
      this.bottleCheckEdit = false
      this.$nextTick(function(){
        this.checkDetailsTab = true
      })
    },
    getDate(data){
      return moment(data).format('MM/DD/YYYY')
    },
    toggleShow(dailyvehicle){

      this.show = !this.show
      this.$nextTick(function(){
              this.getBottleCountHtml(dailyvehicle)
      })
    },
    editPermissionCheck(dailyvehicle){
      var currentDateTime = moment().unix()
      var dailyvehicleDateTime = moment(dailyvehicle.dailyvehicleDate).unix()
      if(((dailyvehicle.dailyvehicleAuthorId == this.userData.id) || this.userData.role != 'user')
            && (moment(currentDateTime).subtract(dailyvehicleDateTime) < 172800 ))
        {
        return true
        }
      else if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
        return true
      }
      else
        {
        return false
        }
    },
    checkBottleEditPermission(data, dailyvehicle){
      var currentDateTime = moment().unix()
      var bottleCheckDateTime = moment(dailyvehicle.updatedAt).format('X')
      if((data.bottleAuthorId == this.userData.id) && (
        moment(currentDateTime).subtract(bottleCheckDateTime) < 172800)){
        return true
      } else {
        return false
      }
    },
    removeBottleData(data){
            var id = this.dailyvehicle.id
            var prevBottle = this.dailyvehicle.dailyvehicleBottleData
            prevBottle.splice(data, 1)
             axios({
            method: 'patch',
            url: '/api/v1/content/dailyvehicle/' + id,
            data: {
                  dailyvehicleBottleData : prevBottle,
                  },
            headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',}
                }).then( response => {
                      bus.$emit('refreshDomEvent')
                              bus.$emit('refreshUserPosts')
                      bus.$emit('refresh-calendar-list')
                    
                   }),
              this.showModalToggle()
            
        },
        getBottleCountHtml(data){
            var sptest = 'span_bottle_count'
            var spanid = document.getElementById(sptest)
            if(spanid == undefined){
            var htmlid = 'bottle___BV_tab_button__'
            var tab = document.getElementById(htmlid)
            if(tab){
              var count = 0
              var data = data.dailyvehicleBottleData
              for(var i in data){
                if(data[i].bottleDataArray){
                count = count + data[i].bottleDataArray.length
                }
              }
              if(count > 0){
                var variant = 'success'
              } else {
                var variant = 'danger'
              }
            tab.insertAdjacentHTML('beforeend', " <span id='span_bottle_count' class='badge badge-" + variant + "'>" + count + "</span>")
            }
            
          }
          
        },
        getBottleCountHtmlOld(data){
          var count = 0
          var data = data.dailyvehicleBottleData
          for(var i in data){
            if(data[i].bottleDataArray){
            count = count + data[i].bottleDataArray.length
            }
          }
          if(count > 0){
            var variant = 'success'
          } else {
            var variant = 'danger'
          }
          return '<span class="badge badge-' + variant + '">' + count + '</span>'
        },
getStationParent(data){
        if(data != undefined && data != ''){
        var parent = this.stationsData.filter(p => {
            return p.stationName == data
        })
        if(parent[0].stationParent != ''){
        return parent[0].stationParent }
        else {
          return parent[0].stationName
        }
        } else {
           return ''
        }
      },
 deleteVehicleCheck(){
       var result = confirm('Are You Sure You Want to Delete this Vehicle Check?')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/dailyvehicle/' + this.dailyvehicle.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    
    this.$nextTick(function(){
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
    })
  }) 
      }
 },
 updateLogbookPost(dailyvehicle){
   var id = dailyvehicle.id
   axios({
    method: 'patch',
    url: '/api/v1/content/dailyvehicle/' + id,
    data: {
          dailyvehicleCheckDetails : this.checkDetails,
          dailyvehicleCheckItems : this.checkItems,
          dailyvehicleHoursMiles : this.checkHours,
          title:  moment(this.dailyvehicle.dailyvehicleDate).format('MM/DD/YYYY') + ' | ' + this.apparatusSelectValue.apparatusName + ' | ' + this.stationSelectValue.stationName + ' | ' + this.dailyvehicle.dailyvehicleShift,
          dailyvehicleStation: this.stationSelectValue.stationName,
          dailyvehicleStationParent: this.getStationParent(this.stationSelectValue.stationName),
          dailyvehicleApparatusId: this.apparatusSelectValue.apparatusName,
          dailyvehicleApparatusDbid: this.apparatusSelectValue.id,

          },
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
  }).then( response => {
 /*   var token = this.$cookie.get('token')
    this.$store.dispatch('LOAD_USER_POSTS', token)
    bus.$emit('refreshDomEvent')
    bus.$emit('refresh-calendar-list') */
    this.dailyvehicle = response.data
    this.editPost = false
            bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
  })}
},


}
</script>

<style scoped>
.flagWarning {
  color: red;
}
.flagGood {
  color: green;
}
input.big {

    padding-left: 4px;
    border-radius: 5px;
  width: 100px;
}
input.small {
  margin: 0;
  padding-left: 4px;
  border-radius: 5px;
  width: 70px;
}
select {
  padding-left: 4px;
  border-radius: 5px;
}
#confirmationTable {
  width: 100%;
  margin: 0 auto 0;
  text-align: center;
}
.headerContainer {
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.Red-shift-header {
  background: #E2070D;
}
.Yellow-shift-header {
  background: #ffc107;
}
.Blue-shift-header {
  background: #007bff;
}
.Green-shift-header {
  background: #0FB906;
}
.headerContainer h3 {
  color: #eee;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.headerContainer h5 {
  color: #eee;
  padding-top: 4px;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.headerRows h6 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #aaa;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
.subHeaderRows h6 {
  margin-top: -2px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  
}
.panelCheck{
  border: 1px solid #777;
  background-color: #f7f6f6;
  border-radius: 0 0 5px 5px;
  padding-bottom: 20px;
}
.checkDetails{
  text-align: center;
}
.checkDetailsDiv {
  text-align: left;
  margin-left: 20px;
}
.checkDetailsWrap {
  border: 1px solid #aaa;
  border-radius: 6px;
}
.checkDetails h5 {
  background: #aaa;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px 5px 0 0;
}
#details {
  background: #fff;
}

p.defDescrip {
  border: 1px solid #E2070D;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: #0FB906;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span.numberDetail {
  background: rgb(90, 90, 90);
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span.No {
  background: #E2070D;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span h6 {
  margin-top: 0;
  padding-bottom: 5px;
  text-align: center;
  width: 80%;
  margin: 0 auto 0;

}
.editRowValue {
  margin-top: 20px;
}
.input-group{
  margin-bottom: 10px;
}
#tablePassDown{
  width:80%;
  padding: 5px;
}

.headingRow {
  color: #777;
  border-top: 1px solid grey;
  margin-bottom: 5px;
}
.headingRow  h5{
  margin-bottom: 0;
}
#dailyvehicleTitle {
  text-align: center;
  cursor: pointer;
  width: 80%;
  background: #fff;
  margin: 0 auto 0;
  width: 100%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;

}
#dailyvehicleTitle:hover {
  background: #ddd;
  border-radius: 5px;

}
 #dailyvehicleUrl {
   text-align: center;
 }
 a {
   color: #E2070D;
   text-decoration: none;
 }
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
text-align: center;
margin-top: 10px;
}
.show-background {
width: 90%;
background: #fff;
margin: 0 auto 0;
padding-bottom: 10px;
margin-bottom: 0;
border-radius: 0 0 10px 10px;
}
.Red-shift{
color: #e2070d;

}
.Yellow-shift {
  color: #ffc107;
}
.Blue-shift{
color: #007bff;
}
.Green-shift{
color: #0FB906;
}
@media screen and (max-width: 768px) {
  #dailyvehicleTitle  {
    width: 95%;

  }
  span h6 {
    width: 95%;
  }
}
  @media screen and (max-width: 460px) {
    .show-background {
    width: 100%;
    }
  .checkDetails.container {
    padding-left: 0;
    padding-right: 0;
  }
}


</style>
